<template>
  <div>
    <molecules-hero-header
      :image-config="{
        img: `${APP_ASSET_BASEURL}edge/static/images/hero/homeloans_hero_images_v3_1x.webp`,
        class: 'max-w-full w-[29.313rem] lg:h-[20.75rem]',
        dimensions: {
          width: 469,
          height: 332
        }
      }"
      :buttons="[
        {
          theme: 'primary',
          text: 'I WANT TO REFINANCE',
          url: verticalData.formPage
        },
        {
          theme: 'secondary',
          text: 'I WANT TO BUY A HOME',
          url: verticalData.formPage
        }
      ]"
      title-tag="p"
      img-alt="The easiest way to get a new home loan"
    >
      <template #header>
        The easiest way to <br /> get a <strong class="font-extrabold">new home loan</strong>
      </template>

      <template #sub-header>
        Let our <strong>expert brokers</strong> take the hassle out of home loans.
        We'll find you the best loan from our panel of 50+ brokers and do all the hard work from application to settlement.
        See why thousands of Australians use Compare Club to keep their <strong>mortgage repayments low</strong>.
      </template>
    </molecules-hero-header>

    <MoleculesTrustpilot
      :layout="!devices.isDesktop.value ? 'compact' : 'default'"
      class="py-8 border border-solid border-gray-350 min-h-[5.875rem] bg-white"
    />

    <molecules-home-company-comparison
      :companies="PARTNER_ITEMS"
      container-class="grid-cols-3 md:grid-cols-6"
      more-button-url="/home-loans/companies/"
      is-center
      image-lazy-load
    >
      <template #header>
        Compare home loans from <span class="font-extrabold">40+ lenders.</span>
      </template>
    </molecules-home-company-comparison>

    <div class="bg-gray-10">
      <molecules-home-talk-to-us
        :buttons="[
          {
            label: 'COMPARE & SAVE',
            to: verticalData.formPage
          }
        ]"
        :card-cta-config="{
          label: verticalData.phoneNumber
        }"
        image-lazy-load
      >
        <template #header>
          Use our <strong class="font-extrabold">Expert<i>Ease</i></strong> to save on<br />
          your <strong class="font-extrabold">home loan</strong>
        </template>

        <template #sub-header>
          Want help finding an affordable refinance rate? Got an investment property and want to cut your mortgage payments? Buying a home and want to find the lowest rate? Everyone’s after something different. Our <strong>experts</strong> help you compare loans from over <strong>40 lenders</strong> to keep your repayments as low as possible.
        </template>
      </molecules-home-talk-to-us>
    </div>

    <molecules-home-talk-to-us
      :buttons="[
        {
          label: 'GET STARTED',
          to: verticalData.calculatorPage,
          hasIcon: true
        }
      ]"
      :card-cta-config="{
        label: verticalData.phoneNumber
      }"
      class="bg-white"
      image-lazy-load
    >
      <template #header>
        <strong class="font-extrabold">Home Loans calculators</strong>
      </template>

      <template #sub-header>
        <p>
          Want to calculate your repayment rate? Or see how much you can borrow? If you need a quick home loan calculation, our range our calculators can help.
        </p>
      </template>

      <template #right-content>
        <NuxtImg
          src="/icons/home-calculator-loan.svg"
          alt="Home loan calculator"
          width="323"
          height="197"
          class="m-auto pb-6"
          loading="lazy"
        />
      </template>
    </molecules-home-talk-to-us>

    <molecules-home-great-deal
      :cards="GREAT_DEAL_ITEMS"
      :col-count="4"
      class="bg-blue-5"
      card-wrapper-max-width="w-wrapper-lg"
      card-class="py-[1.875rem] md:py-6"
      image-lazy-load
    >
      <template #header>
        Our <strong class="font-extrabold">experts</strong> make comparing <strong class="font-extrabold">home loans
          easy.</strong>
      </template>

      <template #sub-header>
        Our four simple steps can help you save.
      </template>
    </molecules-home-great-deal>

    <molecules-home-great-deal
      :cards="REPAYMENTS"
      :col-count="2"
      class="bg-gray-350"
      card-class="p-6 pb-8"
      title-class="!text-left !text-2xl !mb-4"
      description-class="!text-left text-base text-lg"
      image-lazy-load
      :no-scroll="devices.breakpoint('lg')"
    >
      <template #header>
        How our
        <img
          class="inline-block h-9 md:h-10 md:mr-3"
          :src="expertEaseImg"
          width="230"
          height="40"
          alt="Expert ease"
          loading="lazy"
        />

        <strong class="font-extrabold">helps</strong> cut your<br class="md:block hidden" /> mortgage repayments
      </template>

      <template #sub-header>
        Our <strong>experts</strong> start by comparing interest rates from over <strong>40 lenders in Australia.</strong> In a matter of minutes, we can show how much you could save when you apply for a mortgage with us.
      </template>
    </molecules-home-great-deal>

    <div class="bg-white px-4 lg:px-0 pt-[2.125rem] pb-20 lg:pt-20">
      <div class="max-w-wrapper-md mx-auto">
        <p class="text-2xl  md:text-4.5xl text-center leading-10 md:leading-[3rem]">
          How
          <strong class="font-extrabold">our experts</strong> have<br />
          <strong class="font-extrabold">helped Australians</strong>
          like you
        </p>

        <MoleculesTrustpilotReviews
          class="mt-8"
          :tags="vertical.replace(/\s/g, '-')"
        />
      </div>
    </div>

    <molecules-home-guide-tiles
      :guides="GUIDES_ITEMS"
      :action-button-url="VERTICALS[vertical].guidesUrl"
      image-lazy-load
    >
      <template #header>
        <strong class="font-extrabold">Got home loan questions?</strong>
      </template>

      <template #sub-header>
        Want to know more? Our in-depth expert guides have the answers
      </template>
    </molecules-home-guide-tiles>

    <molecules-home-cta
      class="bg-gray-350"
      :btn-has-icon="false"
      :cta-schedule="verticalData.schedule"
      :cta-schedule-weekend="verticalData.schedule_weekend"
      :cta-phone-number="verticalData.phoneNumber"
    >
      <template #header>
        <strong class="font-extrabold">First time </strong>home buyer?
      </template>

      <template #sub-header>
        Our <strong>experts</strong> specialise in helping new buyers get a foot on the property ladder. Speak to us today to see how we can help find you a low rate.
      </template>
    </molecules-home-cta>

    <molecules-faq
      id="faq"
      class="max-w-2.5xl md:py-[5.5rem] py-10 md:px-0 px-4 m-auto"
      title="Compare The Best Home Loan Rates Today"
      title-tag="h1"
      :list="FAQ_ITEMS"
    >
      Here are some of the most frequently asked questions we receive about home loans:
    </molecules-faq>

    <molecules-home-footer-info theme="gray-boxed">
      <template #title>
        Things you should know
      </template>

      <template #description>
        <p>
          * The Interest Rate quoted is for a variable rate owner occupied loan with principal and interest payments, loan to value ratio below 60% and minimum loan amount of $250,000. Interest Rates are correct as at {{ homeLoanRates.cashRateDate }} and subject to change at any time. The comparison rate is based on a loan amount of $150,000, over a 25 year term. Warning: This comparison rate is true only for the example given and may not include all fees and charges.
        </p>

        <p>
          Beat My Loan Pty Ltd ABN 85 613 044 58 of 222 Pitt Street Sydney, trading as Rate Comparison and Compare Club - Home Loans, owns and operates RateComparison.com.au and its associated website. Beat My Loan Pty Ltd is an authorised representative of Alternative Media Pty Ltd (ACL 486326) and fully owned subsidary of Compare Club Group.
        </p>
      </template>
    </molecules-home-footer-info>
  </div>
</template>

<script setup>
import {
  PARTNER_ITEMS,
  GREAT_DEAL_ITEMS,
  REPAYMENTS,
  GUIDES_ITEMS,
  FAQ_ITEMS
} from '~/constants/pages/home-loans'

import HomeLoanService from '~/server/service/home-loan'

const { devices } = deviceChecker()
const service = new HomeLoanService()

const vertical = VERTICAL.HOME_LOANS
const verticalData = VERTICALS[vertical]
const $route = useRoute()

defineOptions({
  name: 'HomeLoans'
})

const homeLoanRates = await (async () => {
  try {
    const responses = await Promise.all([
      useAsyncData(() => service.getVariableRate()),
      useAsyncData(() => service.comparisonRateItem()),
      useAsyncData(() => service.cashRateDateItem())
    ])

    const data = {}

    responses.forEach(item => {
      const payload = item.data.value?.data[0]
      const tempKey = payload.fieldName !== 'cashRateDate'
        ? payload.fieldName
        : 'date'

      data[payload.fieldName] = payload.fieldValue || verticalData.cashRates?.[tempKey]
    })

    return data
  } catch {
    return {
      variableRate: verticalData.cashRates.variableRate,
      comparisonRate: verticalData.cashRates.comparisonRate,
      cashRateDate: verticalData.cashRates.date
    }
  }
})()

generateMeta({
  title: 'Compare Home Loan & Mortgage Rates | Compare Club',
  ogTitle: 'Compare Home Loan & Mortgage Rates | Compare Club',

  description: 'Want a better home loan? Compare Club makes comparing home loans easy. Click here to get help from our experts',
  ogDescription: 'Want a better home loan? Compare Club makes comparing home loans easy. Click here to get help from our experts',

  ogUrl: 'https://compareclub.com.au/home-loans/',
  ogImage: '',
  ogType: 'product'
}, pageIsFunnel($route))

useJsonld(() => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: 'Home loan comparison',
    image: 'https://compareclub.com.au/_ipx/s_190x30/images/cc-logo-new.svg',
    url: 'https://compareclub.com.au/home-loans/',
    description: 'Our brokers help keep the cost of your monthly mortgage low. Our experts help hundreds of homeowners, new buyers, and investors find better value home loans each week.',
    brand: {
      '@type': 'Brand',
      name: 'Compare Club'
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.7',
      bestRating: '5',
      worstRating: '1',
      ratingCount: '3028'
    }
  }
})
</script>
